import { mainRequests } from './custom'
import config from '../config'

export default {
  // address
  getDistrict () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .thirthGet(config.BASE_TIRTH_PATH + '/getDistricts')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getKhoroos (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .thirthGet(config.BASE_TIRTH_PATH + '/getHoroos' + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // CC orders
  getOrdersCC (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customGet(config.BASE_SECOND_PATH + '/getOrdersCC?' + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOrderDetailCC (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customGet(config.BASE_SECOND_PATH + '/getOneOrderCC?' + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOrdersCount (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customGet(config.BASE_SECOND_PATH + '/getOrdersTypeCountCC?' + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  orderCancel (query, body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPost(config.BASE_SECOND_PATH + '/orderCancel?id=' + query, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  pdChangeOrderAddrNote (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPost(config.BASE_SECOND_PATH + '/getNoteFromOrderCC', body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getPdSuggestTypes () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customGet(config.BASE_GET_COMPLAINT_PATH + 'getSuggestToktokMallTypes')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  registerComplaint (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPost(config.BASE_GET_COMPLAINT_PATH + 'registerComplaint', body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  registerSuggest (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPost(config.BASE_GET_COMPLAINT_PATH + 'registerSuggest', body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getPdComplaintTypes () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customGet(config.BASE_GET_COMPLAINT_PATH + 'getComplaintToktokMallTypes')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getReasonsList () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customGet(config.BASE_GET_CC + 'reasons')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getAllOutletName () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customGet(config.BASE_GET_COMPLAINT_PATH + 'getAllOutletName?')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Category
  getCategories () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_CATEGORY)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getCategoriesAsTree () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_CATEGORY + '?tree=true')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createCategory (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_CATEGORY, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteCategory (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .del(config.BASE_CATEGORY + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateCategory (id, body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_CATEGORY + '/' + id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Inventory
  getInventory (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_INVENTORY + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneVariant (variantId) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_INVENTORY + '/' + variantId)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Product
  getSearchProductName (text) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_SEARCH_PRODUCT_NAME + '?text=' + text)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getProducts (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_PRODUCT + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getAllProducts (body, query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_GET_ALL_PRODUCT + query, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneCategoryProducts (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_PRODUCT + '?category_id=' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getProductsWithVariant (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_PRODUCTS_WITH_VARIANT + '?search_text=' + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getProductsWithVariantBySupplierId (supplierId, searchText = '') {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_PRODUCTS_WITH_VARIANT + '?supplier_id=' + supplierId + '&search_text=' + searchText)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addProduct (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_PRODUCT, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteProduct (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .del(config.BASE_PRODUCT + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateProduct (productId, body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_PRODUCT + '/' + productId, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneProduct (productId) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_PRODUCT + '/' + productId)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getProductByVariantId (variantId) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_PRODUCT_BY_VARIANT_ID + '?variant_id=' + variantId)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOptionName (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_GET_OPTIONS_NAME, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOptionValue (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_GET_OPTIONS_VALUE, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getPropertiesName (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_GET_PROPRTY_NAME, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getPropertiesValue (property) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_PROPRTY_VALUE + '?properties_name=' + property)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Brand
  getBrands (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_BRAND + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getBrandsBySupplierId (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_BRANDS_BY_SUPPLIER_ID + '?supplier_id=' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addBrand (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_BRAND, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteBrand (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .del(config.BASE_BRAND + '/' + body.brand_id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateBrand (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_BRAND + '/' + body.brand_id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneBrand (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_BRAND + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Supplier
  getSuppliers (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_SUPPLIER + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getAllSuppliers () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_SUPPLIER)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteSupplier (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .del(config.BASE_SUPPLIER + '/' + body.supplierId)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addSupplier (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_SUPPLIER, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getBrandsBySupplierIds (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_GET_BRANDS_BY_SUPLLIER_IDS, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateSupplier (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_SUPPLIER + '/' + body.supplier_id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneSupplier (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_SUPPLIER + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getSupplierBrands (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_ONESUPPLIER_BRANDS + '?supplier_id=' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getSuppliersByWarehouseId (warehouseId) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_SUPPLIERS_BY_WAREHOUSE_ID + '?warehouse_id=' + warehouseId)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Variant
  addVariant (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_ADD_VARIANT, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateVariant (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_UPDATE_VARIANT, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getProductVariant (producId) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_PRODUCT_VARIANT + '?productId=' + producId)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getAllVariants () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_ALL_VARIANTS)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  changeProductCount (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_CHANGE_PRODUCT_COUNT, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Transfer
  getUncheckedTransferProducts (transferId = null) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_UNCHECKED_TRANSFER_PRODUCTS + `?transfer_id=${transferId}`)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getTransfers (query = '') {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_TRANSFER + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getAllRequiredProduct (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_ALL_REQURIED_PRODUCT + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneTransfer (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_TRANSFER + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addTransfer (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_TRANSFER, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  changeTransfer (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_TRANSFER + '/' + body.transfer_id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  changeTransferStatus (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_CHANGE_TRANSFER_STATUS, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteTransfer (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .del(config.BASE_TRANSFER + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Return
  getUncheckedReturnProducts (transferId = null) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_UNCHECKED_RETURN_PRODUCTS + `?transfer_id=${transferId}`)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getReturns (query = '') {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_RETURN + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneReturn (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_RETURN + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addReturn (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_RETURN, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  changeReturn (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_RETURN + '/' + body.transfer_id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  changeReturnStatus (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_CHANGE_RETURN_STATUS, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteReturn (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .del(config.BASE_RETURN + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Wareshouse
  getWarehouses (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_WAREHOUSE + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneWarehouse (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_WAREHOUSE + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addWarehouse (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_WAREHOUSE, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateWarehouse (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_WAREHOUSE + '/' + body.warehouse_id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteWarehouse (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .del(config.BASE_WAREHOUSE + '/' + body.warehouse_id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Utillity
  imageUpload (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_IMAGE_UPLOAD, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // stats
  getStats () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.GET_STATS)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Users
  getUsers (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_USERS, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  changePassword (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_CHANGE_PASSWORD, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Groups
  getGroups (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_GROUPS, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  cancelGroupOrder (body, id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_GROUP_ORDERS + '/' + id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createGroup (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_CREATE_GROUP, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  deleteGroup (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_DELETE_GROUP, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  addUserToGroup (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_ADD_TO_GROUP, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  checkEbarimt (regno) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customGet('https://rest.toktok.mn/other/checkMerchant?regno=' + regno)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // BASKET
  createBasket (fingerprint) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customGet(config.BASE_SECOND_PATH + config.BASE_CREATE_BASKET + '/' + fingerprint)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addItemToBasket (fingerprint, body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPost(config.BASE_SECOND_PATH + config.BASE_CREATE_BASKET + '/' + fingerprint + config.BASE_ADD_BASKET_ITEM, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  setItemsToBasket (fingerprint, body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPost(config.BASE_SECOND_PATH + config.BASE_CREATE_BASKET + '/' + fingerprint + config.BASE_SET_BASKET_ITEMS, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  removeItemFromBasket (fingerprint, body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPost(config.BASE_SECOND_PATH + config.BASE_CREATE_BASKET + '/' + fingerprint + config.BASE_REMOVE_BASKET_ITEM, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // createOrder (fingerprint) {
  //   return new Promise(function (resolve, reject) {
  //     mainRequests
  //       .customPost(config.BASE_SECOND_PATH + config.BASE_CREATE_ORDER + '/' + fingerprint)
  //       .then(res => {
  //         resolve(res)
  //       })
  //       .catch(err => {
  //         reject(err)
  //       })
  //   })
  // },
  createOrder (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_ORDER, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  orderDelete (id, body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_ORDER + '/' + id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOrdersDetails (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_ORDERS_DETAILS + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addAddressIntoBasket (fingerprint, body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPost(config.BASE_SECOND_PATH + config.BASE_CREATE_BASKET + '/' + fingerprint + config.BASE_ADD_ADDRESS, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addUserIntoBasket (fingerprint, body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPost(config.BASE_SECOND_PATH + config.BASE_CREATE_BASKET + '/' + fingerprint + config.BASE_ADD_USER, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addCouponIntoBasket (fingerprint, body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPostWithOption(config.BASE_SECOND_PATH + config.BASE_CREATE_BASKET + '/' + fingerprint + config.BASE_ADD_DISCOUNT_TO_BASKET, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  removeCouponFromBasket (fingerprint, body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPostWithOption(config.BASE_SECOND_PATH + config.BASE_CREATE_BASKET + '/' + fingerprint + config.BASE_REMOVE_DISCOUNT_TO_BASKET, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // ORDER
  driverAssign () {
  },
  getOrders (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_ORDER + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  orderDrivers (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_ORDER_DRIVERS + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  setFullfillment (productId) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.BASE_ORDER_FULLFILLMENT + '/' + productId)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneOrder (orderNumber) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_ORDER + '/' + orderNumber)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Discount
  getDiscounts (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_DISCOUNT + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addDiscount (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_ADD_DISCOUNT, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneDiscount (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_ONE_DISCOUNT + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOrderGroup (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GROUP_ORDERS + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // ADDRESS
  searchAddress (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customGet('https://rest.toktok.mn/other/addressSearch' + '?address=' + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // search_text
  searchSupplier (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_SEARCH_TEXT + '?search_text=' + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Banner
  getBanners (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customGet(config.BASE_SECOND_PATH + config.BASE_BANNER + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addBanners (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPost(config.BASE_SECOND_PATH + config.BASE_BANNER, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteBanner (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customDelete(config.BASE_SECOND_PATH + config.BASE_BANNER + '/' + body.id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneBanner (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customGet(config.BASE_SECOND_PATH + config.BASE_BANNER + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateBanner (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPut(config.BASE_SECOND_PATH + config.BASE_BANNER + '/' + body.id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  // getRideUsers

  getRiderUsers (query, payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPost(config.GET_RIDER_USERS + query, payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getActiveUsers () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPost(config.GET_ACTIVE_USERS)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  confirmSignUp (user) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPost(config.CONFIRM_RIDER_USER, user)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  changePasswordRider (query, body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPost(config.CHANGE_RIDER + query, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getRider (userName) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customGet(config.GET_USER + '?user_name=' + userName)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateRider (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPost(config.UPDATE_RIDER, payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  removeUser (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customDelete(config.REMOVE_RIDER + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // Block
  getBlocks () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.SUPER_BLOCK)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  getOneBlock (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.SUPER_BLOCK + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  addBlocks (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.SUPER_BLOCK, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  updateBlock (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .put(config.SUPER_BLOCK + '/' + body.super_block_id, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  deleteBlock (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .del(config.SUPER_BLOCK + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  // driver

  assignDriver (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPost(config.BASE_SECOND_PATH + config.BASE_DRIVER_ASSIGN, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getDrivers (type) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_DRIVER + '?type=' + type)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOrdersByDriver () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_ORDER_DRIVER)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  driverOutByDriver (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPost(config.BASE_SECOND_PATH + config.BASE_DRIVER_OUT_DRIVER, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  checkBalance (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customGet(config.BASE_SECOND_PATH + config.BASE_CHECK_BALANCE + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  driverInByDriver (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPost(config.BASE_SECOND_PATH + config.BASE_DRIVER_INBY_DRIVER, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  orderCompleteByDriver (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .customPost(config.BASE_SECOND_PATH + config.BASE_ORDER_COMPLETE_DRIVER, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getOneOrderByDriver (id) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.BASE_GET_ONE_ORDER_DRIVER + '/' + id)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getExpiredVariants (query) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .get(config.GET_EXPIRED_VARIANTS + query)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  setVariantBarCodeAndExpiredDate (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_SET_VARIANT_BARCODE_AND_EXPIREDDATE, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  setVariantRackPosition (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_SET_VARIANT_RACK_POSITION, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getProductPrice (body) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post(config.BASE_COST, body)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}
